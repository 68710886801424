import './App.css';
import { LineChart } from '@mui/x-charts/LineChart';
import { Badge, Box, Button, Chip, Divider, FormLabel, Paper, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Input as BaseInput } from '@mui/base/Input';
import { useEffect, useState } from 'react';
import { secondary } from './Theming';
import TextField from '@mui/material/TextField';
import { clamp } from './Helpers';
import { useSettings } from './contexts/SettingsContext';

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}

const sliderStyle = {
    '& .MuiSlider-thumb': {
        color: secondary.neutral
    },
    '& .MuiSlider-track': {
        color: secondary.neutral
    },
    '& .MuiSlider-rail': {
      color: secondary.neutral
  },
    '& .MuiSlider-mark': {
      color: 'white'
    },
    '& .MuiSlider-active': {
        color: secondary.hover
    }
}

function Input({varKey, readOnly=false, hideRange=false, max=100, min=1, stepSize=0.1, value, onChange=()=>{}, label, hasSlider=true}) {

  const {setSetting , settings} = useSettings();

  const showVarBardge = settings.showVariableKeys;

  return (

            <Box width="300px" >
              <FormLabel style={{fontSize:"0.7em"}} htmlFor={varKey}>
                {showVarBardge && 
                  <Chip size={'small'} sx={{fontSize:'7px'}} label={varKey} variant="outlined" />
                }&nbsp;
                {label}
              </FormLabel>
                <Box display="flex" gap={3}>
                {
                  hasSlider &&
                  <Slider
                        onChange={(e) => onChange(e.target.value)}
                        id="slider1"
                        aria-label="Ambient Temperature"
                        value={value}
                        valueLabelDisplay="auto"
                        shiftStep={stepSize*5}
                        step={stepSize}
                        marks
                        min={min}
                        max={max}
                        sx={{...sliderStyle}}
                        size="small"

                      />
                }
                <TextField
                    type="number"
                    onChange={(e) => onChange(clamp(e.target.value, min, max))}
                    id={varKey}
                    aria-label="Ambient Temperature"

                    value={value}
                    step={stepSize}
                    min={min}
                    max={max}
                    readOnly={readOnly}
                    
                    InputProps={{
                      style: {fontSize: 10, background: (readOnly ? '#ccc !important':'white')},
                    }}    
                    sx={{
                      width: hasSlider ? '150px':'100%',
                      fontSize:'8px !important',
                      border: 'none'
                    }}
                    size="small"
                />
                {
                  hideRange != false &&
                  <div>Between {min} and {max}</div>
                }
              </Box>
              <Divider/>
            </Box>
          
  );
}

export default Input;
