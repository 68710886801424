import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import PopModelling from './Pages/PopModelling';
import SterilizationGrowth from './Pages/SterilizationGrowth';
import SterilizationMaintenance from './Pages/SterilizationMaintenance';
import NavButton from './NavButton';
import NavBar from './NavBar';
import {tabs} from "./Constants";
import { primary, secondary, theme } from './Theming';
import Sidebar from './Sidebar';
import VariableDump from './VariableDump';
import { FinalValueSterilizationGrowth, FinalValueSterilizationMaintenance, PopModelChartData } from './Helpers';
import HelpButtonAndModal from './HelpButtonAndModal';
import { SettingsProvider, useSettings } from './contexts/SettingsContext';

// import SterilizationGrowth from './Pages/SterilizationGrowth';
// import SterilizationMaintenance from './Pages/SterilizationMaintenance';
const defaultVariables = {
  desired_S_N: 0.5,    // Proportion of sterilized dogs
  t0: 0,            // Initial time  
  N0: 2000,         // Initial total population  
  desired_t: 18,    // Time over which to model (in months)  
  h: 1,             // Step size for Runge Kutta method (1 recommended)  
  k: 10000,         // Carrying capacity of environment  
  p_f: 0.5,         // Proportion of population that are female (0-1)  
  s_a: 0.99,        // Adult survivability rate (0-1)  
  s_i: 0.4,         // Infant survivability rate (0-1)  
  l: 6,             // Average litter size  
  r_r: 2/12,        // Reproduction rate/average number of litters (monthly)  
  S0: 0,            // Initial number of sterilised dogs  
  m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
  n_s: 250,         // Number of sterilisations taking place each month  
    
  // N_t               // Total population at time t  
  // S_t               // Number of sterilised dogs at time t    
}

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}
const layoutBreak = 650;

function App() {
  
  const [tab, setTab] = useState(tabs.popModelling);
  const [variables, setVariables] = useState(defaultVariables)
  const [pendingVariables,setPendingVariables] = useState(defaultVariables)
  const [prevVariables, setPrevVariables] = useState(defaultVariables)
  const [chartData,setChartData] = useState({x: [], c1: [], c2: [], c3: []})
  const [prevChartData, setPrevChartData] = useState({x: [], c1: [], c2: [], c3: []})
  const { t0, desired_t, h } = variables;
  const [nsValue, setNSValue] = useState(0)
  const [isMobile, setIsMobile] = useState(window.innerWidth < layoutBreak)
  /* When variables are changed on the input, set the pending ones 
    Pending => Current
    Current => Previous
  */
  const onChangeVariables = (key, value) => {
    setPendingVariables({...pendingVariables, [key]: value});
  }
  // Calcaulate the chart as soon as the page loads
  useEffect( () => {
    onCalculate();
  }, [])


  // Recalculate the chart on each change
  useEffect( () => {
    onCalculate();
  }, [tab])

  const handleResize = ( () => {
    setIsMobile(window.innerWidth < layoutBreak)
  });
  
  // When the window resizes, do stuff
  useEffect(() => {
    window.addEventListener(
        "resize", 
        handleResize,
        false
    );
  }, []);
  
  // When "Calculate" is clicked, build the graph, shift values
  const onCalculate = () => {
    setPrevVariables(variables);
    setVariables(pendingVariables);

    const xAxisChanged = pendingVariables.t0 != prevVariables.t0 || 
          pendingVariables.h != prevVariables.h || 
          pendingVariables.desired_t != prevVariables.desired_t; // Used in setPrevChartData to decide whether or not we burn the previous chart to prevent X axes from being incompatible

    switch(tab) {
      case tabs.popModelling:
        const popModChart = PopModelChartData(pendingVariables);
        setPrevChartData(xAxisChanged ? popModChart : chartData); // Burn the history if the X axis contributors changed
        setChartData(popModChart)
        break;
      case tabs.sterGrowth:
        const n_s_value_sg = FinalValueSterilizationGrowth(pendingVariables);
        const sgChart = PopModelChartData({...pendingVariables, n_s:n_s_value_sg}); // Uses calculated value to replace n_s, which would otherwise come from the input
        setPrevChartData(xAxisChanged ? sgChart : chartData)
        setChartData({...sgChart, 
          sm_NSValue:FinalValueSterilizationMaintenance(pendingVariables),
          sg_NSValue:FinalValueSterilizationGrowth(pendingVariables)
        })
        break;
      case tabs.sterMaint:
        const n_s_value_sm = FinalValueSterilizationGrowth({...pendingVariables});
        const smChart = PopModelChartData({...pendingVariables, n_s:n_s_value_sm}); // Uses calculated value to replace n_s, which would otherwise come from the input
        setPrevChartData(smChart ? smChart : chartData)
        setChartData({...smChart, 
          sm_NSValue:FinalValueSterilizationMaintenance(pendingVariables),
          sg_NSValue:FinalValueSterilizationGrowth(pendingVariables)
        })

        break;
    }
  }

  const resetPendingVariables = () => {
    setPendingVariables(variables)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: primary.neutral,
      },
      secondary: {
        main: secondary.neutral,
      },
    },
  });
  

  const getCalculatedNSValue = () => {
    switch(tab) {
      case tabs.popModelling:
        return variables.n_s                  // Just return the n_s variable here to avoid confusion. This comes from direct input
      case tabs.sterGrowth:
        return chartData.sg_NSValue // Return the value calculated by FinalValueSterilizationGrowth
      case tabs.sterMaint:
        return chartData.sm_NSValue // Return the value calculated by FinalValueSterilizationMaintenance
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <SettingsProvider>
        <div className="App" style={{background: theme.mainBG}}>
          <Box 
            display='flex'
            flexDirection={'column'} 
            height="100%" 
            maxHeight={'100%'} 
          > {/* App */}
            <Box className="AppNav-Container"> {/* Header */}
              <NavBar isMobile={isMobile} currentTab={tab} setTab={setTab} />
            </Box>
            <Box
                className="AppBody-Container" 
                sx={{
                overflow: 'hidden', 
                display:'flex', 
                displayPrint:'block'
              }} 
              style={{flex: 1,  height: '100%'}}
            > {/* App Body */}
              {
                tab == tabs.popModelling && chartData != null &&
                <PopModelling isMobile={isMobile}
                              chartData={chartData} 
                              prevChartData={prevChartData} 
                              prevVariables={prevVariables} 
                              variables={variables}
                ></PopModelling>
              }
              {
                tab == tabs.sterGrowth &&
                <SterilizationGrowth 
                              isMobile={isMobile}
                              chartData={chartData} 
                              prevChartData={prevChartData} 
                              prevVariables={prevVariables}
                              variables={variables}
                ></SterilizationGrowth>
              }
              {
                tab == tabs.sterMaint &&
                <SterilizationMaintenance 
                            isMobile={isMobile}
                            chartData={chartData} 
                            prevChartData={prevChartData} 
                            prevVariables={prevVariables}  
                            variables={variables}
                ></SterilizationMaintenance>
              }
              {
                <Sidebar
                        isMobile={isMobile} 
                        calculatedNSValue={ getCalculatedNSValue() }
                        onChangeVariables={onChangeVariables}
                        variables={pendingVariables}
                        onCalculate={onCalculate}
                        onReset={resetPendingVariables}
                        currentTab={tab}
                />
              }
            </Box>
            <Box> {/** Variable Summary */}
              <VariableDump variables={variables}/>
            </Box>
          </Box>
          
          {
            <HelpButtonAndModal />
          }

          {/* <Box textAlign="center" padding={5} style={{background:theme.contrast, color:theme.contrastText}}>
              Built with love by Alexandra Hammond and Quintan Neville.
          </Box> */}
        </div>
        </SettingsProvider>
    </ThemeProvider>
  );
}

export default App;
