import { Box, Divider, Fab, FormControlLabel, Paper, Switch, Typography } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { ensureFloat, ensureInt } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings} from "./contexts/SettingsContext";
import SidebarContent from "./SidebarContent";
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TuneIcon from '@mui/icons-material/Tune';

const getPanelHeight = () => window.innerHeight - 50;

const Sidebar = (props) => {
    const nsValue = 2;

    const [sidebarOpenMobile, setSidebarOpen] = useState(props.isMobile)
    const [panelHeight, setPanelHeight] = useState(getPanelHeight())

    const handleResize = () => {
        setPanelHeight(getPanelHeight());
    }

    useEffect(() => {
        window.addEventListener(
            "resize", 
            handleResize,
            false
        );
      }, [props.isMobile]);
    

    return (
      <>
        {
            /* Handle MOBILE layout for sidebar container - slides up */
            props.isMobile &&
            <>
                <Fab 
                    sx={{
                        displayPrint: 'none',
                        position: 'absolute',
                        right: 10,
                        bottom: sidebarOpenMobile ? panelHeight - 65 : 10,
                    }}
                    onClick={() => setSidebarOpen(!sidebarOpenMobile)}
                    color="secondary"
                    aria-label="add">
                    <TuneIcon/>
                     { sidebarOpenMobile &&
                        <KeyboardArrowDownIcon sx={{fontSize:'0.8em'}} />
                     }
                     { !sidebarOpenMobile &&
                        <KeyboardArrowUpIcon sx={{fontSize:'0.8em'}} />
                     }
                </Fab>
                <Box sx={{
                        position:'fixed', 
                        displayPrint: 'none',
                        height: panelHeight,
                        display: 'flex',
                        flexDirection: 'column',
                        bottom:0,
                        right: 0,
                        maxWidth: 400,
                        transform: `translate(0, ${sidebarOpenMobile ? 0 : panelHeight}px)`
                    }}>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}> {/** Floating sidebar */}
                        <SidebarContent {...props} ></SidebarContent>
                    </Box>
                </Box>
            </>
        }
        {
            /* Handle DESKTOP layout for sidebar container - right side */
            !props.isMobile &&
                <Box sx={{position:'relative', displayPrint: 'none'}}>
                    <Box style={{
                        height: 'calc(100% - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        top: 0,
                        right: 0
                    }}> {/** Floating sidebar */}
                        <SidebarContent {...props} ></SidebarContent>
                    </Box>
                </Box>
        }

      </>
    )
}

export default Sidebar;