import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import ThemedButton from './ThemedButton';
import { secondary, theme } from './Theming';

const NavButton = ({onClick, isActive, label, style}) => {
    
    const color = {
      ...secondary
    };
    
    const colorSelected = {
        text: secondary.neutral,
        neutral: "#fff",
        hover: "#fff",
        press: "#fff",
      };    

      const restyled  = {
        lineHeight: '10px',
        padding: 0,
        height: '30px',
        padding: '8px',
        margin: '8px',
        border: isActive ? `none` : `none`,
      }

    return (
    <ThemedButton color={isActive ? colorSelected : color} style={restyled} onClick={onClick}>{isActive} {label}</ThemedButton>
    )
}

export default NavButton;