import NavButton from "./NavButton";
import { colors, secondary } from "./Theming";
import Input from "./Input";
import { ensureFloat, ensureInt } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { tabs, variableSet } from "./Constants";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

const VariableDump = ({variables}) => {


    return (
    <TableContainer sx={{display:'none', displayPrint:'block'}} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
                {
                    Object.keys(variables).map( (v) => {
                        return  (
                            <TableRow key={"vd_"+v}>
                                <TableCell><strong>{variableSet[v].label}</strong> ({v})</TableCell>
                                <TableCell>{variables[v]}</TableCell>
                            </TableRow>
                        )
                    })
                }
                
            </TableBody>
            </Table>
        </TableContainer>

    )
}

export default VariableDump;