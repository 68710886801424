import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Paper, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Input as BaseInput } from '@mui/base/Input';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { PopModelChartData, ensureFloat, ensureInt } from '../Helpers';
import { graphColors } from '../Theming';
import StyledChart from './StyledChart';

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}

function PopModelling({chartData,prevChartData,prevVariables,variables,isMobile}) {
  const [windowWidth, setWindowWidth]   = useState(!isMobile ? window.innerWidth-400 : window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight/3);
  const {t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s} = variables;

  // When the window resizes, do stuff
  const handleResize = () => {
    setWindowWidth( !isMobile ? window.innerWidth-400 : window.innerWidth);
    setWindowHeight((window.innerHeight/3));
  }

  useEffect(() => {
    window.addEventListener(
        "resize", 
        handleResize,
        false
    );
  }, [isMobile]);

  return (

        <Box className="PopModelling" display={'flex'} style={{marginTop: '50px', flex: 1,  height: 'calc(100% - 100px)'}}> {/* PopModelling Body */}
          <Box style={{flex: 1, justifyItems: 'center', justifyContent:'center', alignContent:"center"}}> {/* PopModelling Main */}
            {
              /* Total Population at time T (months) */
            }
            <StyledChart isMobile={isMobile}
              xAxisData={chartData.x}
              yAxisLabel={"Number"}
              yMin={0}
              yax={null}
              series={[
                // Previous data from last calculation
                {
                  label: "Population at Month (Last Run)",
                  data: prevChartData.c1,
                  color: `${graphColors.c1}33`
                },
                {
                  label: "Sterilizations Per Month (Last Run)",
                  data: prevChartData.c2,
                  color: `${graphColors.c2}33`
                },
                
                // New data
                {
                  label: "Population at Month",
                  data: chartData.c1,
                  color: `${graphColors.c1}FF`
                },
                {
                  label: "Sterilizations Per Month",
                  data: chartData.c2,
                  color: `${graphColors.c2}FF`
                },



              ]}
              width={windowWidth}
              height={windowHeight}
            />

            {
              /* Number Sterilized Dogs at Time T (months) */
            }
            <StyledChart isMobile={isMobile}
              yMin={0}
              yMax={100}
              xAxisData={chartData.x}
              yAxisLabel={"Percent (%)"}
              series={[

                // Previous data from the last chart
                {
                  data: prevChartData.c3,
                  color:`${graphColors.c3}33`
                },
                // New data
                {
                  data: chartData.c3,
                  color: `${graphColors.c3}FF`
                },

              ]}

              yAxis={[{ label: 'Percent %', min:0, max: 100 }]}
              xAxis={[{ 
                  data: chartData.x
                }]}
              
              width={windowWidth}
              height={windowHeight}
            />


          </Box>
        </Box>
  );
}

export default PopModelling;
