import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Paper, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Input as BaseInput } from '@mui/base/Input';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { PopModelChartData, ensureFloat, ensureInt } from '../Helpers';
import { graphColors, theme } from '../Theming';
import StyledChart from './StyledChart';

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}

function SterilizationGrowth({chartData,prevChartData,prevVariables,variables,isMobile}) {
  const [windowWidth, setWindowWidth]   = useState(!isMobile ? window.innerWidth-400 : window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight/3);
  const {t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s} = variables;

  // When the window resizes, do stuff
  const handleResize = () => {
    setWindowWidth( !isMobile ? window.innerWidth-400 : window.innerWidth);
    setWindowHeight((window.innerHeight/3));
  }

  useEffect(() => {
    window.addEventListener(
        "resize", 
        handleResize,
        false
    );
  }, [isMobile]);


  const { desired_S_N } = variables;
  return (

        <Box className="SterilizationGrowth"  display={'flex'} style={{marginTop: '50px', flex: 1,  height: 'calc(100% - 100px)'}}> {/* SterilizationGrowth Body */}
          <Box style={{flex: 1, justifyItems: 'center', justifyContent:'center', alignContent:"center"}}> {/* SterilizationGrowth Main */}
            {
              /* Total Population at time T (months) */
            }
            <StyledChart isMobile={isMobile}
              xAxisData={chartData.x}
              yAxisLabel={"Number"}
              yMin={0}
              yax={null}
              series={[
                // Previous data from last calculation
                {
                  label: "Population at Month (Last Run)",
                  data: prevChartData.c1,
                  color: `${graphColors.c1}33`
                },
                {
                  label: "Sterilizations Per Month (Last Run)",
                  data: prevChartData.c2,
                  color: `${graphColors.c2}33`
                },
                
                // New data
                {
                  label: "Population at Month",
                  data: chartData.c1,
                  color: `${graphColors.c1}FF`
                },
                {
                  label: "Sterilizations Per Month",
                  data: chartData.c2,
                  color: `${graphColors.c2}FF`
                },



              ]}
              height={windowHeight}
              width={windowWidth}
              
            />

            {
              /* Number Sterilized Dogs at Time T (months) */
            }
            <StyledChart isMobile={isMobile}
              yMin={0}
              yMax={100}
              xAxisData={chartData.x}
              yAxisLabel={"Percent (%)"}
              series={[

                // Previous data from the last chart
                {
                  data: prevChartData.c3,
                  color:`${graphColors.c3}33`
                },
                // New data
                {
                  data: chartData.c3,
                  color: `${graphColors.c3}FF`
                },

              ]}

              yAxis={[{ label: 'Percent %', min:0, max: 100 }]}
              xAxis={[{ 
                  data: chartData.x
                }]}
              height={windowHeight}
              width={windowWidth}
            />

            {/** Box to display calculated results here */}
            <Box marginX={3} marginY={0} marginBottom={'50px'} >
              <Paper padding={2} sx={{background: theme.contrast, color:theme.contrastText, textAlign:'center'}}>
                <Box padding={3} >
                  <Typography>
                    <strong>Number of Required Sterilizations Each Month Required to Reach proportion ({(desired_S_N*100).toFixed(1)}%)</strong> = {chartData.sg_NSValue}
                  </Typography>
                  {/* <Typography fontSize={'10px'}>Last run: {prevChartData.sg_NSValue}</Typography> */}
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
  );
}

export default SterilizationGrowth;
