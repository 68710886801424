import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Paper, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Input as BaseInput } from '@mui/base/Input';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { PopModelChartData, ensureFloat, ensureInt } from '../Helpers';
import { graphColors } from '../Theming';

function StyledChart({isMobile, series, yAxisLabel, xAxisData, yMin, yMax, height=500,width=500}) {

  console.log(height, width)
  const filteredSeries = series // Just raw dog it actually
  return (
    xAxisData != null &&
      <LineChart
          margin={{ top: 10, bottom: 80, left: isMobile ? 60 : 100, right: isMobile ? 30 : 100 }}
          yAxis={[{ label: yAxisLabel, min:yMin, max: yMax }]}
          xAxis={[{ 
            
              data: xAxisData // Ran into an issue where t0 or desired_t changes the X axis. Clear last run if these are different.
          }]}
          series={filteredSeries}
          sx={{ 
            "& .MuiChartsLegend-series text": { fontSize: "0.7em !important" }, 
            "& .MuiChartsLegend-root": {
              transform: "translateY(10px) translateX(-20px) !important",
              fontSize: "0.8em"
            },
            "& .MuiChartsLegend-series": {
            },
            "& .MuiChartsAxis-directionY .MuiChartsAxis-label": {
              transform: "translateX(-20px) !important"
            }
          }}
          width={width}
          height={height}
          slotProps={{
            legend: {
              position: {
                vertical: 'bottom',
                horizontal: 'middle',
              },
              direction: 'row',
              itemGap: 1,
            },
          }}

    />

  );
}

export default StyledChart;
