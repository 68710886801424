export const colors = {
    footer: "#ccc",
    header: "#ccc",
    body: "#fff",
    sidePanel: "#fff",
  }

export const theme = {
    mainBG: "#fefefe",
    contrast: "#FDF0D5",
    contrastText: "#423314"
}

export const secondary = {
    neutral:"#669BBC",
    hover:"#003049",
    press:"#003049",
    text:"#fff",
    200: "#C1121F",
    300: "#C1121F",
    400: "#C1121F",
    500: "#C1121F",
    600: "#C1121F",
}

export const primary = {
    neutral:"#C1121F",
    hover:"#780000",
    press:"#003049",
    text:"#fff",
    200: "#C1121F",
    300: "#C1121F",
    400: "#C1121F",
    500: "#C1121F",
    600: "#C1121F",
}

export const graphColors = {
    c1: "#656DB0",
    c2: "#C76F6F",
    c3: "#84B280",
}