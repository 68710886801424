// Make the chart usable on the frontend
export const PopModelChartData = (values) => {
    const {t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s} = values;
    
    const chart = rungeKutta(t0, N0, desired_t, h, k, clamp(p_f, 0, 1), s_a, s_i, l, r_r, S0, m, n_s)
    return {
      x: chart[1],
      c1: chart[2],
      c2: chart[3],
      c3: chart[4],
    };
}

export const FinalValueSterilizationGrowth = (values) => {
    const {t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s, desired_S_N} = values;

    const  result = growthBinarySearchNS(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, desired_S_N)
    return result;
}

export const FinalValueSterilizationMaintenance = (values) => {
    const {t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s, desired_S_N} = values;

    const  result = maintenanceBinarySearchNS(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, desired_S_N)
    return result;

}

export const clamp = (num, min, max) => {
    return num <= min 
      ? min 
      : num >= max 
        ? max 
        : num
  }

// Graph functions adapted to JS from https://github.com/halexmmond/happy_doggo_population_sterilisation_modelling/blob/master/equation_functions.py
// Helper function for calculating the number of sterilized dogs
const numSterilizedDogs = (S0, s_a, m, n_s, t) => {
    const S_t = ((n_s + ((m + s_a - 1) * S0)) * t) + S0;
    return S_t;
  };
  
  // Differential equation for the total population of stray dogs
  const dNdt = (N_t, k, p_f, s_a, s_i, l, r_r, S0, n_s, t, m) => {
    const dN_dt = N_t * ((k - N_t) / k) * (
      (p_f * s_a * s_i * l * r_r * (1 - ((n_s + ((m + s_a - 1) * S0)) * t) / N_t)) - (1 - s_a) + m
    );
    return dN_dt;
  };
  
  // Runge-Kutta method for solving the differential equation
  const rungeKutta = (t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s) => {
    let t = t0;
    let N_t = N0;
    const t_values = [t0];
    const N_t_values = [N0];
    const S_t_values = [S0];
    const S_N_values = [(S0 / N0) * 100];
  
    while (t < desired_t + 1) {
      const k1 = h * dNdt(N_t, k, p_f, s_a, s_i, l, r_r, S0, n_s, t, m);
      const k2 = h * dNdt(N_t + 0.5 * k1, k, p_f, s_a, s_i, l, r_r, S0, n_s, t + 0.5 * h, m);
      const k3 = h * dNdt(N_t + 0.5 * k2, k, p_f, s_a, s_i, l, r_r, S0, n_s, t + 0.5 * h, m);
      const k4 = h * dNdt(N_t + k3, k, p_f, s_a, s_i, l, r_r, S0, n_s, t + h, m);
  
      N_t += (k1 + 2 * k2 + 2 * k3 + k4) / 6;
  
      t += h;
  
      let S_t = numSterilizedDogs(S0, s_a, m, n_s, t);
  
      if (S_t > N_t) {
        S_t = N_t;
      }
  
      t_values.push(t);
      N_t_values.push(N_t);
      S_t_values.push(S_t);
      S_N_values.push((S_t / N_t) * 100);
  
      if (Math.abs(t - desired_t) < 0.01) {
        break;
      }
    }
  
    return [Math.ceil(N_t), t_values, N_t_values, S_t_values, S_N_values];
  };
  
  // Function for binary search to find n_s that maintains a certain sterilization ratio

//   /** Question... is this used/needed? */
//   const binarySearchNS = (t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, desired_S_N) => {
//     let lower_limit = 0;
//     let upper_limit = 2000;
//     let n_s;
  
//     while (upper_limit - lower_limit > 1) {
//       n_s = Math.ceil((upper_limit + lower_limit) / 2);
//       const S_t = numSterilizedDogs(S0, s_a, m, n_s, desired_t);
  
//       const [N_t] = rungeKutta(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s);
//       const S_N = S_t / N_t;
  
//       if (S_N < desired_S_N) {
//         lower_limit = n_s;
//       } else {
//         upper_limit = n_s;
//       }
//     }
  
//     return n_s + 1;
//   };

  function growthBinarySearchNS(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, desired_S_N) {
    let lowerLimit = 0;
    let upperLimit = 2000;
    let n_s;

    while (upperLimit - lowerLimit > 1) {
        n_s = Math.ceil((upperLimit + lowerLimit) / 2);

        // Assuming numSterilizedDogs is a previously defined function that calculates S(t)
        const S_t = numSterilizedDogs(S0, s_a, m, n_s, desired_t);

        // Assuming rungeKutta is a previously defined function that calculates N(t)
        let [N_t] = rungeKutta(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s);
        if (N_t < 0) {
            N_t = 1; // Correct negative population, if any, to avoid division by zero later
        }

        let S_N = S_t / N_t; // Calculate sterilization proportion

        if (S_N < desired_S_N) {
            lowerLimit = n_s;
        } else {
            upperLimit = n_s;
        }
        
    }

    return n_s + 1;
}


function maintenanceBinarySearchNS(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m) {
    let lowerLimit = 0;
    let upperLimit = 2000;
    let n_s;

    while (upperLimit - lowerLimit > 1) {
        n_s = Math.ceil((upperLimit + lowerLimit) / 2);

        // Assuming dSNdt is a previously defined function
        const dSN_dt = dSNdt(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s);
        

        if (dSN_dt < 0) {
            lowerLimit = n_s;
        } else {
            upperLimit = n_s;
        }
    }

    return n_s + 1;
}


function dSNdt(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s) {
    const S_t = ((n_s + ((m + s_a - 1) * S0)) * desired_t) + S0;
    const dS_dt = n_s + ((m + s_a - 1) * S0);
  
    // Assuming rungeKutta returns an array where the first element is N_t
    const [N_t] = rungeKutta(t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s);
  
    const dN_dt = N_t * ((k - N_t) / k) * (
      (p_f * s_a * s_i * l * r_r * (1 - (((n_s + ((m + s_a - 1) * S0)) * desired_t) / N_t))) - (1 - s_a) + m
    );
  
    const dSN_dt = ((N_t * dS_dt) - (S_t * dN_dt)) / (N_t ** 2);
  
    return dSN_dt;
  }
  

  // Setting values for things:
  export const ensureFloat = (key, value) => {
    if(value == null) {
      return 0;
    }
    let num = parseFloat(value);
    if (isNaN(num)) {
      throw new Error(`Cannot convert value ${key}:${value} to Float`);
    }
    return num;
  }
  
  export const ensureInt = (key, value) => {
    if (value == 0) return 0;
        let num = parseInt(value);
        
    if (isNaN(num)) {
      throw new Error(`Cannot convert value ${key}:${value} to Integer`);
    }
    return num;
  
  }
  