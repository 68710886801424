
export const tabs = {
    popModelling: 0,
    sterGrowth: 1,
    sterMaint: 2,
}

export const tabnames = {
    [tabs.popModelling]: "Population Modelling",
    [tabs.sterGrowth]: "Sterilization Growth",
    [tabs.sterMaint]: "Sterilization Maintenance",
}

export const getTabName = (currentTab) => {
    return tabnames[currentTab];
}

export const variableSet = {
    desired_S_N: {
        label: "Desired Proportion of Sterilized Dogs",
    },
    t0: {
        label: "Initial time",
    },
    N0: {
        label: "Initial total population",
    },
    desired_t: {
        label: "Model duration in months",
    },
    h: {
        label: "Step size for Runge Kutta method",
    },
    k: {
        label: "Environment's carrying capacity",
    },
    p_f: {
        label: "Proportion of females ",
    },
    s_a: {
        label: "Adult survivability rate ",
    },
    s_i: {
        label: "Infant survivability rate ",
    },
    l: {
        label: "Average litter size ",
    },
    r_r: {
        label: "Reproduction rate/average number of litters ",
    },
    S0: {
        label: "Initial number of sterilised dogs"
    },
    m: {
        label: "Net migration rate (positive into environment, negative out)",
    },
    n_s: {
        label: "Number of sterilisations taking place each month"
    }


}