import { Box, Divider, FormControlLabel, Paper, Switch, Typography } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { ensureFloat, ensureInt } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings} from "./contexts/SettingsContext";

const SidebarContent = ({isMobile, variables, onCalculate, currentTab, onChangeVariables, calculatedNSValue}) => {
    const {desired_S_N, t0, N0, desired_t, h, k, p_f, s_a, s_i, l, r_r, S0, m, n_s} = variables;
    const {setSetting , settings} = useSettings();
    const handleSettingsChange = (event) => {
        setSetting(event.target.name, event.target.checked);
    };
    

    return(
        <>
        <Box className="sidebar-container" padding={2}>
                <ThemedButton 
                    onClick={onCalculate}
                    style={
                        isMobile ?
                        {border:'none', width:'calc(100% - 80px)', border: "2px solid white", boxShadow:'1px 3px 10px rgba(0,0,0,.10)'} :
                        {border:'none', width:'100%', border: "2px solid white", boxShadow:'1px 3px 10px rgba(0,0,0,.10)'}
                    } 
                    color={primary}>
                            Calculate
                </ThemedButton>
            </Box>

            <Paper sx={{
                    display: 'flex', 
                    gap: '20px', 
                    position: 'relative',
                    flexDirection:'column', 
                    padding:'30px 20px', 
                    background: colors.sidePanel,
                    overflowY: 'scroll',
                    flex: 1,
                }}>
                
                {
                    /**
                     * Desired S_N value for 
                     */
                    (currentTab == tabs.sterGrowth ||
                    currentTab == tabs.sterMaint) &&
                    <>
                    <Input
                        varKey="desired_S_N"
                        label={variableSet.desired_S_N.label}
                        onChange={e => onChangeVariables('desired_S_N', ensureFloat('desired_S_N',e))}
                        hasSlider={true}
                        sx={{backgroundColor:'#ccc'}}
                        max={1}
                        min={0}
                        stepSize={0.05}
                        value={desired_S_N}
                    />
                    <Divider/>
                    </>
        
                }
                

                <Input
                label={variableSet.t0.label}
                varKey="t0"
                onChange={e => onChangeVariables('t0', ensureInt('t0',e))}
                hasSlider={true}
                max={desired_t-2}
                min={0}
                stepSize={1}
                value={t0}
                />
                

                <Input
                label={variableSet.N0.label}
                varKey="N0"
                onChange={e => onChangeVariables('N0', ensureInt('N0',e))}
                min={0}
                max={k}
                stepSize={100}
                value={N0}
                />

                <Input
                label={variableSet.desired_t.label}
                varKey="desired_t"
                onChange={e => onChangeVariables('desired_t', ensureInt('desired_t',e))}
                max={36}
                min={t0+1}
                stepSize={1}
                value={desired_t}
                />

                <Input
                label={variableSet.h.label}
                varKey="h"
                onChange={e => onChangeVariables('h', ensureInt('h',e))}
                min={1}
                max={5}
                stepSize={1}
                value={h}
                />

                <Input
                label={variableSet.k.label}
                varKey="k"
                onChange={e => onChangeVariables('k', ensureInt('k',e))}
                min={1000}
                max={50000}
                stepSize={250}
                value={k}
                />

                <Input
                label={variableSet.p_f.label}
                varKey="p_f"
                onChange={e => onChangeVariables('p_f', ensureFloat('p_f',e))}
                min={0}
                max={1}
                stepSize={0.05}
                value={p_f}
                />

                <Input
                label={variableSet.s_a.label}
                varKey="s_a"
                onChange={e => onChangeVariables('s_a', ensureFloat('s_a',e))}
                min={0}
                max={1}
                stepSize={0.05}
                value={s_a}
                />

                <Input
                label={variableSet.s_i.label}
                varKey="s_i"
                onChange={e => onChangeVariables('s_i', ensureFloat('s_i',e))}
                min={0}
                max={1}
                stepSize={0.05}
                value={s_i}
                />

                <Input
                label={variableSet.l.label}
                varKey="l"
                onChange={e => onChangeVariables('l', ensureFloat('l',e))}
                min={1}
                max={10}
                stepSize={1}
                value={l}
                />

                <Input
                label={variableSet.r_r.label}
                varKey="r_r"
                onChange={e => onChangeVariables('r_r', ensureFloat('r_r',e))}
                min={0}
                max={1}
                stepSize={0.01}
                value={r_r}
                />

                <Input
                label={variableSet.S0.label}
                varKey="S0"
                onChange={e => onChangeVariables('S0', ensureInt('S0',e))}
                min={0}
                max={1000}
                stepSize={50}
                value={S0}
                />

                <Input
                label={variableSet.m.label}
                varKey="m"
                onChange={e => onChangeVariables('m', ensureFloat('m',e))}
                min={-1}
                max={1}
                stepSize={0.1}
                value={m}
                />
                {
                    /* Different enough to warrant a different variant on the pop page */
                    (currentTab == tabs.sterGrowth || 
                    currentTab == tabs.sterMaint) &&

                    <Input
                        label={"Sterilisations per month (from s_n calculation)"}
                        varKey={"nsValue"}
                         // Calculated in App.js by desired_s_n input and calcs
                        hasSlider={false}
                        disabled={true}
                        value={calculatedNSValue}
                    />              
                } 
                {
                    currentTab == tabs.popModelling &&    
                    <Input
                        label={"Sterilisations per month (n_s)"}
                        onChange={e => onChangeVariables('n_s', ensureFloat('n_s',e))}
                        varKey={"n_s"}
                        min={0}
                        max={500}
                        stepSize={10}
                        value={n_s}
                    />  
                }

                <Box>
                    <Typography variant="caption">Prefs</Typography>
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch checked={settings.showVariableKeys} onChange={handleSettingsChange} name="showVariableKeys" />
                            }
                            label="Show Variables"
                        />
                    </Box>

                </Box>

            </Paper>
    </>
    );
}

export default SidebarContent;